body {
  // background: #fafafa;
  margin: 0;
  padding: 0!important;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:after,
  &::before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}